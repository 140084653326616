const FETCH_DOCUMENT_REQUEST = 'DOCUMENT/FETCH_DOCUMENT_REQUEST';
const FETCH_DOCUMENT_SUCCESS = 'DOCUMENT/FETCH_DOCUMENT_SUCCESS';
const SAVE_DOCUMENT_SUCCESS = 'DOCUMENT/SAVE_DOCUMENT_SUCCESS';
const PUBLISH_DOCUMENT_SUCCESS = 'DOCUMENT/PUBLISH_DOCUMENT_SUCCESS';
const FETCH_IMAGEREFERENCES_REQUEST = 'DOCUMENT/FETCH_IMAGEREFERENCES_REQUEST';
const FETCH_IMAGEREFERENCES_SUCCESS = 'DOCUMENT/FETCH_IMAGEREFERENCES_SUCCESS';

export {
  FETCH_DOCUMENT_REQUEST,
  FETCH_DOCUMENT_SUCCESS,
  SAVE_DOCUMENT_SUCCESS,
  PUBLISH_DOCUMENT_SUCCESS,
  FETCH_IMAGEREFERENCES_REQUEST,
  FETCH_IMAGEREFERENCES_SUCCESS,
};
