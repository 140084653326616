const LIST_SECTIONS_REQUEST = 'HEADER/LIST_SECTIONS_REQUEST';
const LIST_SECTIONS_SUCCESS = 'HEADER/LIST_SECTIONS_SUCCESS';
const LIST_DOCUMENTS_SUCCESS = 'HEADER/LIST_DOCUMENTS_SUCCESS';
const EDIT_SECTION_REQUEST = 'HEADER/EDIT_SECTION_REQUEST';
const EDIT_SECTION_SUCCESS = 'HEADER/EDIT_SECTION_SUCCESS';
const CREATE_DOCUMENT_REQUEST = 'HEADER/CREATE_DOCUMENT_REQUEST';
const CREATE_DOCUMENT_SUCCESS = 'HEADER/CREATE_DOCUMENT_SUCCESS';

export {
  LIST_SECTIONS_REQUEST,
  LIST_DOCUMENTS_SUCCESS,
  LIST_SECTIONS_SUCCESS,
  EDIT_SECTION_REQUEST,
  EDIT_SECTION_SUCCESS,
  CREATE_DOCUMENT_REQUEST,
  CREATE_DOCUMENT_SUCCESS,
};
